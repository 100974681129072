


































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { isArray } from 'lodash';
import {
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue';
import { VueMaskFilter } from 'v-mask';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { UserTypeEnum } from '@/enums/crm/user-type.enum';
import SettingsService from '@/services/crm/settings.service';
import ActivityService from '@/services/crm/activity.service';
import dayjs from '@/plugins/dayjs';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CrmActivityCalendarEventForm from '@/components/crm/activity-calendar-event-form.vue';
import ContentDialog from '@/components/content-dialog.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { toIsoDateString } from '@/utils/formatters/to-iso-date-string';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CrmActivityCalendarEvent from '@/components/crm/activity-calendar-event.vue';
import CrmActivityCalendarEventEmailView from '@/components/crm/activity-calendar-event-email-view.vue';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import ProcessModel from '@/models/crm/process.model';
import RouterService from '@/services/router.service';
import ProspectService from '@/services/crm/prospect.service';
import ClientService from '@/services/crm/client.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ClientModel from '@/models/crm/client.model';
import ContactService from '@/services/crm/contact.service';
import CrmAppBar from '@/components/crm/app-bar.vue';
import SettingsModel from '@/models/crm/settings.model';
import RepresentativeModel from '@/models/crm/representative.model';
import UserModel from '@/models/user.model';
import RepresentativeService from '@/services/crm/representative.service';
import { ICalendarFilter } from '@/interfaces/crm/calendar-filter.interface';
import DataGridFilter from '@/components/data-grid-filter.vue';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { DateHelper } from '@/utils/helpers/date-helper';
import Tooltip from '@/components/tooltip.vue';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { ActivityEventEmailOptionsEnum } from '@/enums/crm/activity-event-title-format.enum';

type TimeUnit = {
  date: string;
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
};

type ClickEvent = {
  nativeEvent: PointerEvent;
  event: ICalendarEvent;
};

type DragEvent = {
  event: IKeyValue<number>;
  timed: boolean;
};

type ChangeEvent = {
  start: TimeUnit;
  end: TimeUnit;
};

type DataGridFilterConfig = {
  type: string | null;
  attendant: UserModel[] | null;
  representative: RepresentativeModel[] | null;
  consolidatedSale: boolean;
  situation: string[];
};

@Component({
  components: {
    CrmAppBar,
    ContentDialog,
    Tooltip,
    DataGridFilter,
    AgGridWrapper,
    AgGridVue,
    CrmActivityCalendarEventForm,
    CrmActivityCalendarEvent,
    ConfirmationDialog,
    CrmActivityCalendarEventEmailView,
  },
})
export default class CrmGlobalCalendar extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  settings: SettingsModel | null = null;

  userContactInfo: UserContactInfo | null = null;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmRepresentativeService)
  private representativeService!: RepresentativeService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  ready = false;

  focus = '';

  type = 'week';

  tab = 0;

  typeToLabel = {
    month: `${this.$tc('global.month')}`,
    week: `${this.$tc('global.week')}`,
    day: `${this.$tc('global.day')}`,
  };

  events: ICalendarEvent[] = [];

  colors = new Map([
    ['eventBlue', 'rgb(58, 83, 155)'],
    ['eventYellow', 'rgb(247, 202, 24)'],
    ['eventGreen', 'rgb(30, 130, 76)'],
    ['eventRed', 'rgb(192, 57, 43)'],
    ['eventOrange', 'rgb(249, 105, 14)'],
    ['eventPurple', 'rgb(145, 61, 136)'],
  ]);

  situationOptions = [
    {
      description: this.$t('crm.activityCalendar.status.open'),
      colorCode: 'eventBlue',
    },
    {
      description: this.$t('crm.activityCalendar.status.reschedule'),
      colorCode: 'eventYellow',
    },
    {
      description: this.$t('crm.activityCalendar.status.complete'),
      colorCode: 'eventGreen',
    },
    {
      description: this.$t('crm.activityCalendar.status.delayed'),
      colorCode: 'eventRed',
    },
    {
      description: this.$t('crm.activityCalendar.status.holiday'),
      colorCode: 'eventOrange',
    },
    {
      description: this.$t('crm.activityCalendar.status.otherAttendant'),
      colorCode: 'eventPurple',
    },
  ];

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    eventForm: {
      show: false,
      id: null,
      cloneId: null,
      startDate: null,
      endDate: null,
      timed: null,
      process: null,
    },
    sendEventEmail: {
      show: false,
      event: null,
      client: null,
    },
  };

  activeEvent: ICalendarEvent | null = null;

  selectedElement: EventTarget | string = '';

  showEvent = false;

  markingAsClose: IKeyValue<boolean> = {};

  eventTitleFormat: ActivityEventEmailOptionsEnum = ActivityEventEmailOptionsEnum.TipoHistorico;

  showAskForSaleBeforeClose = false;

  // #region filters

  filterOpen = 0;

  multipleFilterChanged = false;

  filters: DataGridFilterConfig = {
    type: null,
    attendant: null,
    representative: null,
    consolidatedSale: false,
    situation: [],
  };

  typeOptions: IKeyValue[] = [
    {
      code: ClientTypeEnum.Client,
      name: this.$t('crm.view.globalCalendar.filter.client').toString(),
    },
    {
      code: ClientTypeEnum.Prospect,
      name: this.$t('crm.view.globalCalendar.filter.prospect').toString(),
    },
  ];

  attendantOptions: UserModel[] = [];

  representativeOptions: RepresentativeModel[] = [];

  // #endregion

  // #region Grid

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'start', sort: 'desc' }],
    itemsPerPage: 999,
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.cnpj')}`,
        colId: 'cnpj',
        field: 'cnpj',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.event')}`,
        colId: 'formattedData',
        field: 'name',
        sortable: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams): string => {
          let text = `<h4 class="text-uppercase mb-1" style="color: ${params.data.color}">${params.data.name}</h4>`;
          if (params.data.client) {
            text += `<div class="body-2"><b>${this.$t('crm.view.globalCalendar.grid.client')}:</b> `;
            if (params.data.cnpj) {
              const cnpj = params.data.cnpj.replace(/\D/g, '');
              text += `<span>${VueMaskFilter(
                cnpj,
                cnpj.length > 11 ? '##.###.###/####-##' : '###.###.###-##',
              )} - </span>`;
            }
            text += `${params.data.client}</div>`;
          }

          if (params.data.city) {
            text += `<div class="body-2"><b>${this.$t('crm.view.globalCalendar.grid.city')}:</b> ${
              params.data.city
            }</div>`;
          }

          if (params.data.description) {
            text += params.data.description;
          }

          if (params.data.attendant) {
            text += `<div class="body-2"><b>${this.$t('crm.view.globalCalendar.grid.attendant')}:</b> ${
              params.data.attendant?.nome
            }</div>`;
          }

          if (params.data.consolidatedSale) {
            text += `<div class="body-2"><b>${this.$t('crm.view.globalCalendar.grid.consolidatedSale')}</b></div>`;
          }

          return text;
        },
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.description')}`,
        colId: 'description',
        field: 'description',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.client')}`,
        colId: 'client',
        field: 'client',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.city')}`,
        colId: 'city',
        field: 'city',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.attendant')}`,
        colId: 'attendant',
        field: 'attendant.nome',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.consolidatedSale')}`,
        colId: 'consolidatedSale',
        field: 'consolidatedSale',
        hide: true,
      },
      {
        headerName: `${this.$t('crm.view.globalCalendar.grid.date')}`,
        colId: 'startDate',
        field: 'start',
        sortable: true,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params: ValueFormatterParams): string => {
          const formattedDate = this.$d(params.data.start, params.data.timed ? 'dateTime' : 'short');
          return formattedDate;
        },
        maxWidth: 145,
        cellClass: 'dateISO',
      },
    ],
  };

  selected: ICalendarEvent[] = [];

  client!: ClientModel;

  isRescheduling = false;

  flagEnviarEmail = false;

  eventModel!: ActivityEventModel | null;

  // #endregion

  private lastLoadedPeriod: string[] = [];

  private newEvent: ICalendarEvent | null = null;

  private createEventStart: number | null = null;

  private dragEvent: IKeyValue<number> | null = null;

  private dragTime: number | null = null;

  private loaded = false;

  @Prop()
  onloadOpen!: number | null;

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadUserContactInfo();
      await this.loadFilterOptions();

      this.eventTitleFormat = await this.activityService.getFormatoTitulo();

      this.ready = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
    setInterval(async () => {
      if (this.events.length) {
        await this.loadEvents(this.lastLoadedPeriod[0], this.lastLoadedPeriod[1], true, false);
      }
    }, 300000);
  }

  async onCalendarChange(event: ChangeEvent): Promise<void> {
    await this.loadEvents(event.start.date, event.end.date);

    if (!this.loaded && this.onloadOpen) {
      this.onEditEvent({ id: this.onloadOpen } as ICalendarEvent);
    }

    this.loaded = true;
  }

  onViewDay({ date }: IKeyValue): void {
    this.focus = date;
    this.type = 'day';
  }

  onSetToday(): void {
    this.focus = '';
  }

  onPrevPeriod(): void {
    if (this.$refs.calendar) {
      const prevKey = 'prev';
      this.$refs.calendar[prevKey]();
    }
  }

  onNextPeriod(): void {
    if (this.$refs.calendar) {
      const nextKey = 'next';
      this.$refs.calendar[nextKey]();
    }
  }

  onCreateEvent(ev: TimeUnit): void {
    const date = dayjs(ev.date).toDate();
    this.createCalendarEvent(date, date);
  }

  onShowEvent(ev: ClickEvent): void {
    const { nativeEvent, event } = ev;
    const open = () => {
      this.activeEvent = event;
      this.selectedElement = nativeEvent.target as EventTarget;

      requestAnimationFrame(() => {
        const request = requestAnimationFrame(() => {
          this.showEvent = true;
        });
        return request;
      });
    };

    if (this.showEvent) {
      this.closeEvent();
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  async onEditEvent(event: ICalendarEvent): Promise<void> {
    if (!event.id) {
      return;
    }

    this.eventModel = await this.activityService.getEvent(event.id);
    this.closeEvent();
    this.dialogConfig.eventForm.id = event.id;
    this.dialogConfig.eventForm.cloneId = null;
    this.dialogConfig.eventForm.show = true;
    this.dialogConfig.eventForm.process = null;
  }

  onCloneEvent(event: ICalendarEvent): void {
    if (!event.id) {
      return;
    }

    this.closeEvent();

    this.dialogConfig.eventForm.id = null;
    this.dialogConfig.eventForm.cloneId = event.id;
    this.dialogConfig.eventForm.show = true;
  }

  onRescheduleEvent(event: ICalendarEvent): void {
    if (!event.id) {
      return;
    }

    this.isRescheduling = true;
    this.dialogConfig.confirmation.show = true;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.message = this.$t('crm.view.globalCalendar.dialog.confirmReschedule');
    this.dialogConfig.confirmation.onChoice = (accept: boolean) => {
      this.dialogConfig.confirmation.show = false;
      if (accept) {
        this.onMarkEventAsClose(true, false);
        this.dialogConfig.eventForm.id = null;
        this.dialogConfig.eventForm.cloneId = event.id;
        if (event.process != null) {
          const process = new ProcessModel();
          process.id = event.process.id as number;
          this.dialogConfig.eventForm.process = process;
        }
        this.dialogConfig.eventForm.show = true;
      } else {
        this.isRescheduling = false;
      }
    };
  }

  onRescheduleBtn(eventId: string): void {
    if (this.activeEvent !== null) {
      const loader = this.$loading.show();
      this.isRescheduling = true;
      this.onMarkEventAsClose(true, false);
      this.dialogConfig.eventForm.id = null;
      this.dialogConfig.eventForm.cloneId = eventId;
      this.dialogConfig.eventForm.show = true;

      setTimeout(() => {
        loader.hide();
      }, 1000);
    }
  }

  onFormCancel():void {
    if (this.isRescheduling) {
      this.isRescheduling = false;
    }
  }

  async onSendEmail(event: ICalendarEvent): Promise<void> {
    if (!event.id) {
      return;
    }

    this.eventModel = await this.activityService.getEvent(event.id);

    this.closeEvent();

    const loader = this.$loading.show();
    try {
      await this.loadClient(event);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }

    this.dialogConfig.sendEventEmail.client = this.client;
    this.dialogConfig.sendEventEmail.event = event;
    this.dialogConfig.sendEventEmail.show = true;
  }

  onDeleteEvent(event: ICalendarEvent): void {
    this.beforeDeleteConfirmation(`${this.$t('global.youAreSureDeleteRecord')}`, async (accept: boolean) => {
      if (accept) {
        const loader = this.$loading.show();
        try {
          await this.activityService.delete(event?.id as number);
          this.events = this.events.filter((x) => x.id !== event.id);
          this.closeEvent();
        } catch (err) {
          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
        }
      }
    });
  }

  async onMarkEventAsClose(value: boolean, isNotReschedule = true): Promise<void> {
    if (!value || !this.activeEvent) {
      return;
    }

    this.markingAsClose = { ...this.markingAsClose, [this.activeEvent.id || 0]: true };
    const event = await this.activityService.getEvent(this.activeEvent.id as number);

    if (event.tipoHistorico.flagPassivoVenda && isNotReschedule) {
      this.markingAsClose[this.activeEvent.id || 0] = false;
      this.showAskForSaleBeforeClose = true;
    } else {
      try {
        await this.activityService.close(this.activeEvent.id as number, false, this.isRescheduling);

        await this.loadEvents(
          toIsoDateString(this.activeEvent.start),
          toIsoDateString(this.activeEvent.end || this.activeEvent.start),
          true,
          true,
        );

        this.closeEvent();
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.markingAsClose[this.activeEvent.id || 0] = false;
      }
    }
  }

  async onCompleteMarkingAsClose(consolidateSale: boolean): Promise<void> {
    this.showAskForSaleBeforeClose = false;

    if (!this.activeEvent) {
      return;
    }

    this.markingAsClose = { ...this.markingAsClose, [this.activeEvent.id || 0]: true };

    try {
      await this.activityService.close(this.activeEvent.id as number, consolidateSale);

      await this.loadEvents(
        toIsoDateString(this.activeEvent.start),
        toIsoDateString(this.activeEvent.end || this.activeEvent.start),
        true,
        true,
      );

      this.showEvent = false;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.markingAsClose[this.activeEvent.id || 0] = false;
    }
  }

  async onAfterSaveEvent(result: ActivityEventModel): Promise<void> {
    if (this.isRescheduling) {
      this.isRescheduling = false;
      this.dialogConfig.eventForm.process = null;
    }
    await this.loadEvents(toIsoDateString(result.dataHoraInicio), toIsoDateString(result.dataHoraFim), true, true);
    if (result.flagEnviarEmail) {
      this.flagEnviarEmail = true;
      this.eventModel = result;
      const iCalendarEvent = this.filteredEventsBySituation.find((e) => e.id === result.id);
      if (iCalendarEvent) {
        this.onSendEmail(iCalendarEvent);
      }
    }
  }

  onAfterSendEmail(sent: boolean): void {
    this.flagEnviarEmail = false;
    this.dialogConfig.sendEventEmail.show = false;
    if (sent) this.dialogConfig.eventForm.show = false;
  }

  // #region Drag event

  startDrag(ev: DragEvent): void {
    if (ev.event && ev.timed) {
      this.dragEvent = ev.event;
      this.dragTime = null;
    }
  }

  endDrag(): void {
    if (this.newEvent) {
      this.createCalendarEvent(this.newEvent.start, this.newEvent.end, true);
    }

    this.events = this.events.filter((x) => !x.temporary);
    this.newEvent = null;
    this.createEventStart = null;
  }

  cancelDrag(): void {
    if (this.newEvent) {
      const i = this.events.indexOf(this.newEvent);
      if (i !== -1) {
        this.events.splice(i, 1);
      }
    }

    this.newEvent = null;
    this.createEventStart = null;
  }

  startTime(tms: TimeUnit): void {
    const mouse = CrmGlobalCalendar.toTime(tms);

    if (this.dragEvent && this.dragTime === null) {
      this.dragTime = mouse - this.dragEvent.start;
    } else {
      this.createEventStart = CrmGlobalCalendar.roundTime(mouse);
      this.newEvent = {
        temporary: true,
        name: 'Novo Evento',
        color: 'grey',
        start: new Date(this.createEventStart || 0),
        end: new Date(this.createEventStart || 0),
        timed: true,
      };

      this.events.push(this.newEvent);
    }
  }

  mouseMove(tms: TimeUnit): void {
    const mouse = CrmGlobalCalendar.toTime(tms);

    if (this.newEvent && this.createEventStart !== null) {
      const mouseRounded = CrmGlobalCalendar.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.createEventStart);
      const max = Math.max(mouseRounded, this.createEventStart);

      this.newEvent.start = new Date(min);
      this.newEvent.end = new Date(max);
    }
  }

  static roundTime(time: number, down = true): number {
    const roundTo = 10; // minute scale
    const roundDownTime = roundTo * 60 * 1000;

    return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
  }

  static toTime(tms: TimeUnit): number {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }

  // #endregion

  onFilterChange(type: string): void {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    this.applyFilter();
  }

  async applyFilter(): Promise<void> {
    this.multipleFilterChanged = false;

    try {
      await this.loadEvents(this.lastLoadedPeriod[0], this.lastLoadedPeriod[1], true, false);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  onAddEvent(): void {
    this.createCalendarEvent(new Date());
  }

  async onCellClick(event: IGridCellEvent<ICalendarEvent>): Promise<void> {
    if (!event.data) {
      return;
    }

    this.dialogConfig.eventForm.id = event.data.id;
    this.dialogConfig.eventForm.cloneId = null;
    this.dialogConfig.eventForm.show = true;
    this.dialogConfig.eventForm.process = null;
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ICalendarEvent[]): void {
    this.selected = selected;
  }

  onExport(selected: ICalendarEvent[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredEventsBySituation.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Eventos',
        columnWidth: 300,
        fileName: ActivityService.generateGlobalEventsExportFilename(new Date()),
      });
    }
  }

  get filteredEventsBySituation(): ICalendarEvent[] {
    let filteredEvents = this.events;
    if (this.tab === 1) {
      filteredEvents = filteredEvents
        .filter((event) => dayjs(event.start)
          .isBetween(this.lastLoadedPeriod[0], this.lastLoadedPeriod[1]));
    }

    if (this.filters.situation.length) {
      return filteredEvents.filter((item) => item.styleName && this.filters.situation.includes(item.styleName));
    }

    return filteredEvents;
  }

  get eventFormDialogTitle(): string {
    let titleKey = 'crm.activityCalendar.dialog.calendarEventForm.newTitle';

    if (this.dialogConfig.eventForm.id) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.editTitle';
    } else if (this.isRescheduling) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.rescheduleTitle';
    } else if (this.dialogConfig.eventForm.cloneId) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.duplicateTitle';
    }

    return `${this.$t(titleKey)}`;
  }

  get sendEventEmailDialogTitle(): string {
    const event = this.dialogConfig.sendEventEmail.event as ICalendarEvent;
    const title = event && event.name;
    return `${this.$t('crm.activityCalendar.dialog.activityCalendarEventView.title', { title })}`;
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore: string[] = [];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get notNormalUser(): boolean {
    return this.settings?.loggedUserType !== UserTypeEnum.Normal;
  }

  get canDeleteCalendar(): boolean {
    return this.settings?.flagExcluiAgenda === true;
  }

  private async loadFilterOptions(): Promise<void> {
    if (this.userContactInfo !== null && !this.notNormalUser) {
      this.filters.attendant = [{
        id: this.userContactInfo.id,
        nome: this.userContactInfo.nome,
        email: null,
        isRootUser: false,
        isAtivo: true,
      }];
    }

    const tasks = [
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
        if (this.userContactInfo) {
          const attendant = this.attendantOptions.find((x) => x.id === this.userContactInfo?.id);
          if (attendant) {
            this.filters.attendant = [attendant];
          }
        }
      }),
      this.getRepresentatives().then((result) => {
        this.representativeOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private closeEvent(): void {
    this.showEvent = false;
    // workaround to force detach v-menu activator
    this.selectedElement = '.empty';
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants(false, true);
  }

  private async getRepresentatives(): Promise<RepresentativeModel[]> {
    return this.representativeService.getRepresentatives();
  }

  private beforeDeleteConfirmation(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  private createCalendarEvent(startDate: Date, endDate?: Date, timed?: boolean): void {
    this.dialogConfig.eventForm.id = null;
    this.dialogConfig.eventForm.cloneId = null;
    this.dialogConfig.eventForm.startDate = startDate;
    this.dialogConfig.eventForm.endDate = endDate;
    this.dialogConfig.eventForm.timed = timed;

    const currentRoute = this.routerService.route();
    const processId = currentRoute.query && currentRoute.query.processId;

    if (processId) {
      const process = new ProcessModel();
      process.id = processId as unknown as number;
      this.dialogConfig.eventForm.process = process;
    }

    this.dialogConfig.eventForm.show = true;
  }

  private async loadClient(event: ICalendarEvent): Promise<void> {
    if (event.clientType === ClientTypeEnum.Prospect) {
      this.client = (await this.prospectService.get(event.idProspect as number))?.clientModel;
    }
    this.client = (await this.clientService.get((event.cnpj || '').replace(/\D/g, ''), ClientTypeEnum.Client));
  }

  private async loadEvents(start: string, end: string, ignoreCache = false, attachEvents = false): Promise<void> {
    // If is change to period lowest or equal than last loaded period, do nothing
    const lastStart = this.lastLoadedPeriod[0];
    const lastEnd = this.lastLoadedPeriod[1];
    if (!ignoreCache && lastStart && lastEnd) {
      const isStartWithinPeriod = start >= lastStart && start <= lastEnd;
      const isEndWithinPeriod = end <= lastEnd && end >= lastStart;
      if (isStartWithinPeriod && isEndWithinPeriod) {
        return;
      }
    }

    const loader = this.$loading.show();
    try {
      const filter = this.parseFilter(start, end);

      const calendarEvents = await this.activityService.getCalendarEvents(filter);

      const events: ICalendarEvent[] = attachEvents ? this.events : [];

      calendarEvents.forEach((event) => {
        let styleNameAux!: string;
        styleNameAux = event.styleName;
        if (styleNameAux === 'eventCyan') {
          styleNameAux = 'eventYellow';
        }

        let splitName = event.nome ? event.nome.split(' ') : event.titulo;
        if (Array.isArray(splitName) && splitName.length > 1) {
          splitName = `${splitName[0]} ${splitName[1]}`;
        }

        let calendarEventName = `${splitName} | `;

        if (this.eventTitleFormat === ActivityEventEmailOptionsEnum.TipoHistorico) {
          calendarEventName += `${event.tipoHistorico ? event.tipoHistorico?.nome : ''}`;
        } else {
          calendarEventName += `${event.titulo}`;
        }

        const calendarEvent: ICalendarEvent = {
          id: event.id,
          name: calendarEventName,
          start: new Date(
            event.dataInicio.toString().includes(' ')
              ? dayjs(event.dataInicio)
                .utc(true)
                .format()
                .toString()
                .substring(0, event.dataInicio.toString().lastIndexOf(':') + 3)
              : event.dataInicio.toString().substring(0, event.dataInicio.toString().lastIndexOf(':') + 3),
          ),
          end: new Date(
            event.dataFim.toString().includes(' ')
              ? dayjs(event.dataFim)
                .utc(true)
                .format()
                .toString()
                .substring(0, event.dataFim.toString().lastIndexOf(':') + 3)
              : event.dataFim.toString().substring(0, event.dataFim.toString().lastIndexOf(':') + 3),
          ),
          color: this.colors.get(styleNameAux) || '',
          styleName: styleNameAux,
          timed: !event.diaTodo,
          status: event.situacao,
          clientType: event.tipo,
          client: event.nome || '',
          cnpj: event.cnpj,
          idProspect: event.idProspect,
          tipoHistorico: event.tipoHistorico,
          city: event.cidade,
          attendant: event.atendente,
          description: event.descricao,
          consolidatedSale: event.efetuouVenda === 1,
          createdBy: event.usuarioInclusao ? event.usuarioInclusao.nome : '',
          process: event.processo,
        };

        if (attachEvents) {
          const existentIndex = this.events.findIndex((e) => e.id === calendarEvent.id);

          if (existentIndex > -1) {
            this.events[existentIndex] = { ...calendarEvent };
          } else {
            events.push(calendarEvent);
          }
        } else {
          events.push(calendarEvent);
        }
      });

      this.events = [...events];

      if (!attachEvents) {
        this.lastLoadedPeriod = [start, end];
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  private parseFilter(startDate: string, endDate: string): ICalendarFilter {
    const parsedFilter: ICalendarFilter = {
      startDate,
      endDate,
    };

    if (this.filters.type) {
      parsedFilter.type = this.filters.type;
    }

    if (this.filters.attendant?.length) {
      parsedFilter.attendant = this.filters.attendant.map((x) => x.id);
    }

    if (this.filters.representative?.length) {
      parsedFilter.representative = this.filters.representative.map((x) => x.codigo);
    }

    if (this.filters.consolidatedSale) {
      parsedFilter.onlyConsolidatedSale = true;
    }

    return parsedFilter;
  }

  private async loadUserContactInfo(): Promise<void> {
    try {
      this.settings = await this.settingsService.getSettings();
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }
}
